.main {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-block: 50px;
}

.top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 12px;

}

.title {
    font-size: 38px;
    color: #000000;
    font-weight: 700;
}

.subtitle {
    color: #5E5E5E;
    font-size: 20px;
}

.class {
    display: flex;
    justify-content: space-between;
    padding-top: 96px;
    gap: .5rem;
}

.leftSide {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.6rem;
    place-items: center;
}

.card {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 378px;
    height: 194px;
}



.text {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.title2 {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
}

.text2 {
    font-size: 16px;
    color: #696969;
}

.shadow {
    position: absolute;
    right: 0;
    top: 0;
    opacity: .8;
}

.img2 {
    width: 250px;
    height: 250px;
}

@media screen and (max-width:1240px) {
    .main {
        padding-block: 40px;
        flex-direction: column;
        gap: 30px;
    }

    .top {
        gap: 10px;
    }

    .title {
        font-size: 38px;
    }

    .subtitle {
        font-size: 20px;
    }

    .class {
        padding-top: 50px;
        padding-bottom: 30px;
    }

    .img {
        width: 120px;
        height: 120px;
    }

    .zikhand {
        display: none;
    }

    .card {
        flex-direction: column;
        align-items: initial;
        width: 200px;
        height: 300px;
    }

    .leftSide {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    }

    .text {
        gap: 1px;
    }

    .title2 {
        font-size: 18px;
    }

    .text2 {
        font-size: 16px;
    }
}

@media screen and (max-width:768px) {
    .main {
        padding-block: 40px;
    }

    .top {
        gap: 5px;
    }

    .title {
        font-size: 28px;
    }

    .subtitle {
        font-size: 18px;
    }

    .class {
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .card {
        justify-content: center;
        text-align: left;
    }
}

@media screen and (max-width:425px) {
    .main {
        padding-block: 40px;
    }

    .top {
        gap: 14px;
    }

    .title {
        font-size: 24px;
        line-height: 24px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .leftSide {
        place-items: center;
    }

    .card {
        width: 250px;
    }
}
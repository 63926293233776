


.App {
  /* text-align: center; */
  width: 75%;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (max-width: 639px) {
  /* .App {
    width: 100%;
  } */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.overlay {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 0;
  left: 0;
}

.imgDiv {
  height: 100vh;
}

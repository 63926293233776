.main {
    background: #F7F7F7;
    display: flex;
    align-items: center;
    gap: 77px;
    padding-block: 82px;
}

.img {
    width: 507px;
    height: 507px;
}

.title {
    margin-bottom: 24px;
    font-size: 40px;
    color: #1E1E1E;
    font-weight: 700;
}

.paraContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 19px;
    color: #696969;
}

@media screen and (max-width:1240px) {
    .main {
        flex-direction: column-reverse;
        gap: 50px;
    }


    .title {
        font-size: 32px;
    }

    .paraContainer {
        font-size: 18px;
    }

    .img {
        width: 307px;
        height: 307px;
    }
}



@media screen and (max-width:768px) {
    .main {
        padding-block: 40px;
        gap: 30px;
    }

    .title {
        font-size: 28px;
    }

    .paraContainer {
        font-size: 18px;
        line-height: 28px;
    }

    .img {
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width:639px) {

    .img {
        display: none;
    }
}

@media screen and (max-width:425px) {

    .title {
        font-size: 26px;
    }

    .paraContainer {
        font-size: 18px;
        line-height: 28px;
    }
}
.main {
    height: 100%;
    padding-block: 68px;
    display: flex;
    align-items: center;
    gap: 50px;

}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


@media screen and (max-width:1240px) {
    .main {
        align-items: flex-start;
        flex-direction: column;
        gap: 30px;
    }

    .aboutText {
        font-size: 38px;
    }

    .info {
        font-size: 24px;
        line-height: 32px;
    }

    .img {
        width: 120px;
        height: 120px;
    }
}

@media screen and (max-width:768px) {
    .main {
        padding-block: 40px;
    }

    .aboutText {
        font-size: 28px;
    }

    .info {
        font-size: 20px;
        line-height: 32px;
    }

    .img {
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width:639px) {
    .main {
        padding-inline: 24px;
    }

    .head {
        padding: 0;
    }
}

@media screen and (max-width:425px) {
    .main {
        padding-block: 40px;
    }

    .aboutText {
        font-size: 24px;
    }

    .info {
        font-size: 18px;
        line-height: 28px;
    }

    .img {
        width: 70px;
        height: 70px;
    }
}
.modal {
	background: rgba(49, 70, 61, 0.44);
	position: fixed;
	inset: 0;
	z-index: 5;

	.modalContainer {
		position: absolute;
		height: 100%;
		width: 147px;

		.modalContent {
			transition: all 350ms ease-in-out;
			opacity: 0;

			.content {
				background: #ffffff;
				height: auto;
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				padding: 40px;
				border-radius: 10px;
				width: 50px;

				.buttonContainer {
					display: flex;
					justify-content: end;

					.button {
						position: absolute;
						width: 35px;
						height: 35px;
						opacity: 0.7;
						cursor: pointer;
						top: 22px;

						@media screen and (max-width: 500px) {
							width: 35px;
							height: 35px;
						}

						@media screen and (max-width: 300px) {
							margin-right: 15px;
						}
					}
				}
				.hide {
					display: none;
				}
			}
			.hide {
				padding: 0 20px;
			}
		}
	}

	.padRight {
		right: 0;
		min-width: 425px;

		.modalContent {
			transform: translateX(100%);
			box-shadow: -15px 4px 18.9px 0px rgba(198, 198, 198, 0.3);

			.content {
				padding: 0;
				.buttonContainer {
					.button {
						right: 15px;
					}
				}
			}
		}

		.active {
			transform: translateX(0);
			opacity: 1;
		}
	}

	.padLeft {
		left: 0;

		.modalContent {
			transform: translateX(-100%);
			.content {
				.buttonContainer {
					.button {
						left: 39.5%;
					}
				}
			}
		}

		.active {
			transform: translateX(0);
			opacity: 1;
		}
	}
}

.modalHistory {
	inset: initial;
	top: 0;
	right: 0;
	background-color: transparent;
}

.modalAlternate {
	background: #25242499;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	inset: 0;
	z-index: 5;

	.modalContainer {
		position: relative;
		width: max-content;

		.buttonContainer {
			display: flex;
			justify-content: end;
			margin-bottom: 10px;

			.button {
				width: 40px;
				height: 40px;

				cursor: pointer;

				@media screen and (max-width: 500px) {
					width: 30px;
					height: 30px;
				}
			}
		}

		.modalContent {
			transition: all 350ms ease-in-out;
			opacity: 0;
			transform: translateY(100%);

			.content {
				width: 800px;
				background: #ffffff;
				height: 325px;
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				padding: 40px;
				border-radius: 12px;
				overflow-y: scroll;

				@media screen and (max-width: 820px) {
					width: 700px;
				}

				@media screen and (max-width: 720px) {
					width: 600px;
				}

				@media screen and (max-width: 620px) {
					width: 500px;
				}

				@media screen and (max-width: 530px) {
					padding: 35px 30px;
					width: 450px;
				}

				@media screen and (max-width: 485px) {
					width: 400px;
				}

				@media screen and (max-width: 440px) {
					width: 370px;
				}

				@media screen and (max-width: 400px) {
					width: 340px;
				}

				@media screen and (max-width: 350px) {
					width: 300px;
				}

				@media screen and (max-width: 320px) {
					width: 280px;
				}

				@media screen and (max-width: 300px) {
					padding: 30px 20px;
					width: 260px;
				}

				.hide {
					display: none;
				}

				.exe {
					display: flex;
					flex-direction: column;

					.top {
						display: flex;
						flex-direction: column;
						gap: 12px;
						text-align: center;

						.name {
							color: #1e1e1e;
							font-size: 18px;
							font-weight: 700;
						}

						.role {
							font-size: 17.7px;
						}
					}

					.line {
						height: 0.6px;
						width: 50%;
						background: #000000;
						margin-block: 20px;
						align-self: center;
						opacity: 0.5;
					}

					.info {
						display: flex;
						flex-direction: column;
						gap: 1.2rem;
						color: #696969;
					}
				}
			}

			.content::-webkit-scrollbar {
				display: inline-flex;
				width: 8px;
			}

			.content::-webkit-scrollbar-thumb {
				background: #333;
				border-radius: 20px;
			}
			.paddless {
				padding: 0;
			}

			.extend {
				@extend .content;
				@media screen and (min-width: 768px) {
					width: 60px;
				}
				@media screen and (min-width: 1024px) {
					width: 90px;
				}
			}
		}

		.active {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

.main {
    display: flex;
    align-items: center;
    margin-top: 80px;

}

.writeUp {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 648px;
    margin-bottom: 2rem;
}

.header {
    font-size: 40px;
    line-height: 2.9rem;
    font-weight: 900;
    color: #1E1E1E;
}

.content {
    font-size: 19px;
    color: #696969;
}

.links {
    display: flex;
    gap: 1rem;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:1240px) {
    .main {
        display: flex;
        align-items: initial;

    }

    .header {
        font-size: 32px;
        line-height: 2.4rem;
    }

    .content {
        font-size: 18px;
    }

    .img {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width:1023px) {
    .img {
        display: none;
    }
}

@media screen and (max-width:768px) {
    .main {
        margin-top: 2rem;
    }


    .header {
        font-size: 24px;
    }

    .content {
        font-size: 18px;
        line-height: 28px;
    }
}

@media screen and (max-width:425px) {
    .main {
        margin-top: 2rem;
    }

    .header {
        font-size: 24px;
    }

    .content {
        font-size: 18px;
        line-height: 28px;
    }
}
.carosel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
}

.heading {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    font-size: 38px;
    color: #000000;
    font-weight: 700;
}

.subtitle {
    font-size: 18px;
    color: #5E5E5E;
}

.card {
    position: relative;
    background-color: #FFFFFF;
    padding: 16px;
    border-radius: 12px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    width: 280px;
}

.card:hover {
    box-shadow: 0px 5px 34px -4px rgba(0, 0, 0, 0.1);
    transition: all 300ms ease-in-out;
    transform: scale(1.02);
}

.cardAlt {
    background: #F6F6F6;
}

.imgPeople {
    height: 250px;
    width: 100%;
    border-radius: 8px;
    object-fit: fill;
}

.Directors {
    padding-block: 71px;
}

.detailsContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 5px;
    width: 100%;

}

.details {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 100%;
}

.name {
    font-size: 18px;
    color: #1E1E1E;
    font-weight: 500;
}

.post {
    font-size: 15px;
    color: #6A6A6A;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
}

.read {
    color: #608E75;
    font-weight: 600;
    font-size: 15px;
}

.groupImage {
    width: 100%;
    display: flex;
    gap: 1.3rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:1240px) {
    .card {
        width: 350px;
    }

    .imgPeople {
        height: 300px;
    }

    .details {
        gap: 0;
    }

    .name {
        font-size: 20px;
    }

    .post {
        font-size: 16px;
    }

    .read {
        font-size: 16px;
    }
}

@media screen and (max-width:1200px) {
    .card {
        width: 300px;
    }

    .detailsContent {
        flex-direction: column;
    }

}

@media screen and (max-width:1024px) {
    .card {
        width: 250px;
    }

    .imgPeople {
        height: 250px;
    }

    .name {
        font-size: 18px;
    }

    .post {
        font-size: 14px;
    }

    .imgPeople {
        height: 250px;
    }

    .heading {
        gap: .5rem;
    }

    .title {
        font-size: 28px;
    }

    .subtitle {
        font-size: 18px;
    }
}

@media screen and (max-width:768px) {
    .read {
        font-size: 14px;
    }

    .carosel {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        margin-bottom: 30px;
    }
}


@media screen and (max-width:639px) {
    .Carosel2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .carosel {
        flex-direction: column;
        align-items: initial;
    }

    .card {
        width: 350px;
    }
}

@media screen and (max-width:468px) {
    .title {
        font-size: 20px;
    }

    .subtitle {
        font-size: 16px;
    }
}